import React from 'react'
import Footer from './footer'
import Header from './header'

const Layout = (props) => {
  return (
    <div className={props.is404 && "flex flex-col is404"}>
      <Header />
      {props.is404 && (
        <div className='flex-grow'>
          {props.children}
        </div>
      )}

      {!props.is404 && props.children}
      <Footer />
    </div>
  )
}

export default Layout